import React, { useEffect, useState } from "react";

export default function ScrollToTop() {
  const [isVisible, setIsVisible] = useState(false);

  // Top: 0 takes us all the way back to the top of the page
  // Behavior: smooth keeps it smooth!
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    // The Button is displayed after scrolling for 500 pixels
    const toggleVisibility = () => {
      if (window.pageYOffset > 500) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);

    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  return (
    <>
      {isVisible && (
        <div>
          <button
            style={{
              width: "60px",
              height: "60px",
              backgroundColor: "rgb(101, 92, 151)",
              marginRight: "20px",
              marginBottom: "20px",
            }}
            className="scroll-top "
            onClick={scrollToTop}
          >
            <img
              style={{ width: "30px" }}
              src="images/icon/chevron-w.png"
              alt="icon"
            />
          </button>
        </div>
      )}
    </>
  );
}
