import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Routes from './router/Routes';
import ScrollToTop from './components/ScrollToTop';
import AOS from 'aos';
import 'aos/dist/aos.css';

const App = () => {
  useEffect(() => {
    AOS.init({
      duration: 1200,
    });
  }, []);
  window.addEventListener('load', AOS.refresh);

  const googleAnalyticsId = 'G-EW8S8KETYJ';

  return (
    <>
      <Helmet>
        .<title>René - Saas Project management for the rest of us</title>
        <meta property='og:site_name' content='René' />
        <meta property='og:url' content='https://renesaas.com' />
        <meta property='og:type' content='website' />
        <meta
          property='og:title'
          content='René: Creative PM Saas as simple as possible'
        />
        <meta
          name='keywords'
          content='agency, business, clean, corporate, creative, minimal, modern, multipurpose, portfolio, showcase, Project Management Saas agency, software company, sass, startup, web, web design, web development'
        />
        <meta
          name='description'
          content='René is an easy to use creative saas for project management.'
        />
        <meta
          name='description'
          content='Saas Project Management Application with a shallow learning curve'
        />
        {/* Global site tag (gtag.js) - Google Analytics  */}
        <script
          async
          src={`https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsId}`}
        ></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', "${googleAnalyticsId}");
        `}
        </script>
        <script>
          var _paq = window._paq = window._paq || []; /* tracker methods like
          "setCustomDimension" should be called before "trackPageView" */
          _paq.push(['trackPageView']); _paq.push(['enableLinkTracking']);
          (function() _paq.push(['setTrackerUrl',
          '//analytics.xoommit.com/'+'matomo.php']) _paq.push(['setSiteId',
          '1']); var d=document, g=d.createElement('script'),
          s=d.getElementsByTagName('script')[0]; g.async=true;
          g.src='//analytics.xoommit.com/'+'matomo.js';
          s.parentNode.insertBefore(g,s); )();
        </script>
      </Helmet>
      {/* End Seo Helmt */}

      <ScrollToTop />
      <Routes />
    </>
  );
};

export default App;
